import ImgError from "../../assets/images/error-404.svg";
import { THEME_COLOR } from "./../../config/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
export default function error() {
  return (
    <>
    <HelmetProvider>
          <Helmet>
          <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/css/theme.css?ver=3.0.3"
            />
            <title>OnBoardSoft - Online Onboarding Software</title>
          </Helmet>
        </HelmetProvider>
        <div class="nk-main ">
            
            <div class="nk-wrap nk-wrap-nosidebar">
                
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle wide-md mx-auto">
                        <div class="nk-block-content nk-error-ld text-center">
                            <img class="nk-error-gfx" src={ImgError} alt="" />
                            <div class="wide-xs mx-auto">
                                <h3 class="nk-error-title">Oops! Why you’re here?</h3>
                                <p class="nk-error-text">We apologize for the inconvenience. It appears that you are trying to access a page that either has been deleted or never existed.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        </>
  );
}