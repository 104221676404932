import React, { Component } from 'react';

/*class TawkChat extends Component {
  componentDidMount() {
    // Tawk.to script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66758c17eaf3bd8d4d13011b/1i0timfi4'; // Replace with your Tawk.to property ID
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  render() {
    return <div></div>; // You can customize the rendering if needed
  }
}

export default TawkChat;*/

class TawkChat extends Component {
  componentDidMount() {
    // Check if the src (Tawk.to widget URL) is provided
    const { widgetSrc } = this.props;

    if (widgetSrc) {
      // Tawk.to script
      var Tawk_API = Tawk_API || {};
      var Tawk_LoadStart = new Date();
      (function() {
        var s1 = document.createElement('script'),
          s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = widgetSrc; // Use widgetSrc from props
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  }

  render() {
    const { widgetSrc } = this.props;
    // Render nothing if widgetSrc is not provided
    if (!widgetSrc) {
      return null;
    }
    return <div></div>; // Placeholder or chat container if needed
  }
}

export default TawkChat;
